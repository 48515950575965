<template>
  <div :class="modalBackground" @click="handleClick" />
</template>
<script>
import { EventType, MODAL_BACKGROUND } from '@/constants'
const CSN_MODAL_BACKGROUND = 'csn-modal-background'
const CSN_MODAL_BACKGROUND_HIDDEN = 'csn-modal-background-hidden'

export default {
  name: MODAL_BACKGROUND,
  props: {
    isActive: Boolean,
    closesModal: Boolean,
  },
  data() {
    return {
      modalHiding: null,
    }
  },
  computed: {
    modalBackground() {
      return this.isActive ? CSN_MODAL_BACKGROUND : CSN_MODAL_BACKGROUND_HIDDEN
    },
  },
  methods: {
    handleClick() {
      if (!this.closesModal) {
        return
      }

      this.$emit(EventType.CLOSE)
    },
  },
}
</script>
